import React from 'react'
import Secondary from 'components/Secondary'
import { Col, Grid, Row } from 'react-flexbox-grid'
import Table from 'components/Table'
import ButtonDownload from './ButtonDownload'
import Ruble from 'components/Ruble'
import Copy from 'components/Copy'
import ymReachGoal from 'utils/ymReachGoal'
import LinkDuplicate from 'components/LinkDuplicate'
import Button from 'components/Button'

const Success = ({ order }) => {
	const { databases } = order
	const columns = [
		{
			key: 'name',
			title: null,
		},
		{
			key: 'action',
			title: null,
		},
	]
	const source = databases.map(database => {
		return {
			name: (
				<strong>
					<a href={`/db-${database.type}.html`} target="_blank" rel="nofollow noopener noreferrer">
						{database.name}
					</a>
				</strong>
			),
			action: <ButtonDownload to={database.link} />,
		}
	})

	let promoExpired = null
	if (order.promo) {
		const date = new Date(order.promo.expiredAt)
		const months = [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря',
		]
		promoExpired = `${date.getDate()} ${months[date.getMonth()]}`
	}

	return (
		<div>
			<h1 className="text-center mb-lg">Спасибо вам за&nbsp;покупку</h1>

			{order.promo && (
				<div className="text-center mb-xl">
					<h2 className="mb-sm h3">
						Промо-код на&nbsp;{order.promo.discount}
						<Ruble />
					</h2>
					<p className="fs-h4 mb">
						Используйте его <LinkDuplicate to="/dbs.html#list">на&nbsp;любые базы</LinkDuplicate>&nbsp;до {promoExpired}
					</p>
					<div className="fs-h1 color-green-base">
						<strong>{order.promo.name}</strong>
					</div>
					<div className="mb-sm">
						<Copy
							value={order.promo.name}
							icon="copy"
							theme="default"
							size="sm"
							isNoBorder
							callback={() => ymReachGoal('click-copy-promo')}
						/>
					</div>
					<Button isLink to="/dbs.html#list" title="Открыть полный каталог баз" theme="default">
						Открыть каталог
					</Button>
				</div>
			)}

			<div className="text-center mb-xl">
				<h2 className="mb h3">Сервисы рассылок</h2>
				<p className="mb">
					<a href="/r/smspobeda" target="_blank" rel="nofollow noopener noreferrer">
						SMS Победа
					</a>{' '}
					&mdash; рассылка в&nbsp;WhatsApp, Viber, Телеграм
					<br />
					<Secondary>Вы&nbsp;получите скидку&nbsp;10% в&nbsp;виде кешбэка</Secondary>
				</p>
			</div>

			<h2 className="text-center mb-sm">Скачайте базы ниже</h2>
			<Secondary className="text-center mb">Данная страница будет недоступна через 14&nbsp;дней</Secondary>
			<Grid fluid className="mb-lg">
				<Row>
					<Col lgOffset={1} lg={10}>
						<Table columns={columns} source={source} priorityWidth={['name']} />
					</Col>
				</Row>
			</Grid>

			<Secondary className="text-center">
				Желаем процветания вашему бизнесу
				<br />
				и&nbsp;успешной работы с&nbsp;данными!
			</Secondary>
		</div>
	)
}

export default Success
