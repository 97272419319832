import React from 'react'
import Button from 'components/Button'
import PropTypes from 'prop-types'

const Cancel = props => {
	const { title, url } = props

	return (
		<div className="text-center">
			<h1 className="mb-lg">{title}</h1>
			{url && (
				<Button theme="success" icon="card" isLink to={url}>
					Попробовать снова
				</Button>
			)}
		</div>
	)
}

Cancel.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string,
}

export default Cancel
